import { Stage } from "../configuration";

export const OMNIA_PROD_HOSTNAME = "omnia.it.a2z.com";

/* @VisibleForTesting */
export const OMNIA_GAMMA_HOSTNAME = "gamma.omnia.it.a2z.com";

/* @VisibleForTesting */
export const OMNIA_BETA_HOSTNAME = "beta.omnia.it.a2z.com";

export const OMNIA_DEV_HOSTNAME = "dev-dsk.omnia.it.a2z.com";

export const CONNECT_DEV_HOSTNAME = "dev-dsk.connect.it.a2z.com";

export const CONNECT_PROD_HOSTNAME = "connect.it.a2z.com";

export const CONNECT_BETA_HOSTNAME = "beta.connect.it.a2z.com";

export const CONNECT_API_BETA_URL = "https://api.beta.connect.it.a2z.com";

export const CONNECT_API_PROD_URL = "https://api.connect.it.a2z.com";

export const ASSIST_SURVEY_PROD_URL =
  "https://share.amazon.com/sites/Assist%20Feedback%20Form/Lists/Assist%20Form%20%20GSD2%20to%20GSD1/Item/newifs.aspx";

export const ASSIST_SURVEY_TEST_URL = "https://example.com/";

export const QUITANIEVES_PROD_ENDPOINT = "quitanieves.it.a2z.com/collector";

export const QUITANIEVES_BETA_ENDPOINT =
  "beta.quitanieves.it.a2z.com/collector";

export const BOMGAR_LOGIN_BETA_URL =
  "https://beta.screenshare.it.a2z.com/saml?reason=console";

export const BOMGAR_LOGIN_PROD_URL =
  "https://screenshare.it.a2z.com/saml?reason=console";

export const CHAT_ESCALATION_QUEUE = "its_eng_chat_escalation";

export interface EnvConfig<T> {
  prodValue: T;
  gammaValue: T;
  betaValue: T;
  devValue?: T;
}

/**
 * Gets a configuration value depending on the environment stage.
 * @param prodValue The value for production
 * @param gammaValue The value for gamma
 * @param betaValue The value for beta
 * @param devValue The value for development
 */
function getConfigBySourceUrl<T>(envConfig: EnvConfig<T>) {
  if (
    window.location.hostname === OMNIA_DEV_HOSTNAME ||
    window.location.hostname === CONNECT_DEV_HOSTNAME
  ) {
    return envConfig.devValue;
  } else if (
    window.location.hostname === OMNIA_BETA_HOSTNAME ||
    window.location.hostname === CONNECT_BETA_HOSTNAME
  ) {
    return envConfig.betaValue;
  } else if (window.location.hostname === OMNIA_GAMMA_HOSTNAME) {
    return envConfig.gammaValue;
  } else if (
    window.location.hostname === OMNIA_PROD_HOSTNAME ||
    window.location.hostname === CONNECT_PROD_HOSTNAME
  ) {
    return envConfig.prodValue;
  } else {
    return false;
  }
}

export const enableChatLiveliness = () => {
  return getConfigBySourceUrl({
    prodValue: true,
    gammaValue: true,
    betaValue: true,
    devValue: true,
  } as EnvConfig<boolean>);
};

export const enableTransferCfmWidget = () => {
  return getConfigBySourceUrl({
    prodValue: true,
    gammaValue: true,
    betaValue: true,
    devValue: true,
  } as EnvConfig<boolean>);
};

export const enableAudioNotifications = () => {
  return getConfigBySourceUrl({
    prodValue: false,
    gammaValue: true,
    betaValue: true,
    devValue: true,
  } as EnvConfig<boolean>);
};

export const isTranslationEnabled = () => {
  return !!getConfigBySourceUrl<boolean>({
    prodValue: false,
    gammaValue: false,
    betaValue: true,
    devValue: true,
  });
};

export const quitanievesEndpoint = () => {
  return getConfigBySourceUrl({
    prodValue: QUITANIEVES_PROD_ENDPOINT,
    gammaValue: QUITANIEVES_PROD_ENDPOINT,
    betaValue: QUITANIEVES_BETA_ENDPOINT,
    devValue: QUITANIEVES_BETA_ENDPOINT,
  } as EnvConfig<string>) as string;
};

export const connectHostname = () => {
  let connectHostname = getConfigBySourceUrl({
    prodValue: CONNECT_PROD_HOSTNAME,
    gammaValue: CONNECT_PROD_HOSTNAME,
    betaValue: CONNECT_BETA_HOSTNAME,
    devValue: CONNECT_BETA_HOSTNAME, // change to your ada endpoint for testing
  } as EnvConfig<string>) as string;

  if (!connectHostname) {
    console.error(
      "Failed to determine the connectHostname based on window.location.hostname. Defaulting to `https://api.beta.connect.it.a2z.com`."
    );
    connectHostname = CONNECT_API_BETA_URL;
  }

  return connectHostname;
};

export const bomgarLoginLink = () => {
  let bomgarLoginLink;
  bomgarLoginLink = getConfigBySourceUrl({
    prodValue: BOMGAR_LOGIN_PROD_URL,
    gammaValue: BOMGAR_LOGIN_PROD_URL,
    betaValue: BOMGAR_LOGIN_BETA_URL,
    devValue: BOMGAR_LOGIN_BETA_URL,
  } as EnvConfig<string>) as string;

  if (!bomgarLoginLink) {
    console.error(
      "Failed to determine the bomgarLoginLink based on window.location.hostname. Defaulting to `https://beta.screenshare.it.a2z.com/saml?reason=console`."
    );
    bomgarLoginLink = BOMGAR_LOGIN_BETA_URL;
  }

  return bomgarLoginLink;
};

export const connectApiHostname = () => {
  let connectApiHostname = getConfigBySourceUrl({
    prodValue: CONNECT_API_PROD_URL,
    gammaValue: CONNECT_API_PROD_URL,
    betaValue: CONNECT_API_BETA_URL,
    devValue: CONNECT_API_BETA_URL,
  } as EnvConfig<string>);

  if (!connectApiHostname) {
    console.error(
      `Failed to determine the Connect API hostname based on window.location.hostname. Defaulting to ${CONNECT_API_BETA_URL}.`
    );
    connectApiHostname = CONNECT_API_BETA_URL;
  }

  return connectApiHostname;
};

/** Gets the stage from window.location.hostname object - it checks for both Connect and Omnia domains. */
export function getStage(): Stage {
  let stage = getConfigBySourceUrl({
    prodValue: "prod",
    betaValue: "beta",
    gammaValue: "gamma",
    devValue: "dev",
  } as EnvConfig<Stage>);

  if (!stage) {
    console.error(
      "Failed to determine the stage based on window.location.hostname. Defaulting to beta."
    );
    stage = "beta";
  }

  return stage;
}
