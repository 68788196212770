import "./style.scss";

import { default as Button } from "@amzn/awsui-components-react/polaris/button";
import { default as Select } from "@amzn/awsui-components-react/polaris/select";
import { TranslationSupportedLanguage } from "@amzn/it-support-connect-api-model";
import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  getBaseWindowOrigin,
  getCcpWindowUrl,
  isWindowedCcp,
} from "../../configuration";
import { CcpManager, WindowMessage } from "../../connect/ccp-manager";
import { CONSULTATION_BROADCAST_CHANNEL } from "../../constants";
import { agentChatLanguageSet } from "../../state/actions";
import { useDispatch, useSelector } from "../../state/hooks";
import { AssistSurveyPanel } from "../AssistSurvey/index";
import { CcpLoader } from "../CcpLoader";
import { enableChatLiveliness, isTranslationEnabled } from "../config";
import { ExternalSessionControl } from "../ExternalSessionControl";
import { useInstanceSelector } from "../hooks/instance-selector";
import { IconOmnia } from "../IconOmnia";
import { IconPolaris } from "../IconPolaris";
import PlaceholderCcp from "../PlaceholderCcp";
import { SettingsPanel } from "../SettingsPanel";
import { TransferSurvey } from "../TransferSurvey";
import { TransferToConsultant } from "../TransferToConsultant";
import { Translation } from "../Translation";
import { TranslationSurvey } from "../TranslationSurvey";
import { BaseFC } from "../types";

export interface CcpContainer extends BaseFC {
  readonly onPopClick: () => void;
  readonly onCloseClick: () => void;
}

/**
 * These are the different types of option menus.
 * The Instance menu lets the agent to change the instance which they are connected to. Changing this will cause the CCP to reload.
 * The Settings menu lets the agent to change settings and preferences for different feature, such as translation.
 */
type OptionMenu = "Instance" | "Settings";

/**
 * A Container for the CCP which is to be used both in popped out and embedded applications.
 * It receives 2 props: "onPopClick", "onCloseClick"
 * "onPopClick" and "onCloseClick" are handlers that are called when the "Popout/in" button is clickor the "close" button
 */
export const CcpContainer = forwardRef<HTMLDivElement, CcpContainer>(
  ({ onPopClick, onCloseClick, style }, ref) => {
    const dispatch = useDispatch();
    // Custom Hooks
    const {
      selectedInstanceOption,
      setSelectedInstanceOption,
      options,
      selectedInstance,
      setSelectedInstance,
      instances,
    } = useInstanceSelector();

    // Local State
    const [optionsMenu, setOptionsMenu] = useState<OptionMenu | undefined>(
      undefined
    );

    // Shared State
    const loadingOrError = useSelector((state) => state.ccpLoading);
    const isCcpPoppedOutOfWindow = useSelector((state) => state.ccpInPopup);
    const ccpPopupError = useSelector((state) => state.ccpPopupError);
    const agentChatLanguage = useSelector((state) => state.agentChatLanguage);
    const ccpInitialized = useSelector((state) => state.ccpInitialized);

    // broadcast channel is created for both Main and CCP windows
    // In main window, it is used for catching events coming from Omnia
    // In CCP window, it is used to forward the event to the channel in TransferToConsultant tab
    const consultation_broadcast_channel: MutableRefObject<BroadcastChannel> = useRef(
      new BroadcastChannel(CONSULTATION_BROADCAST_CHANNEL)
    );

    // Hook for setting up and cleaning up the broadcast channels when CCP window is popped out/in
    useEffect(() => {
      // Method to send message to the ccp window
      const OnMessage = (event: any) => {
        CcpManager.instance.postMessage({
          channel: CONSULTATION_BROADCAST_CHANNEL,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          data: event.data,
        });
      };

      // Only add event listener to the Main window when the CCP window is popped out
      if (isCcpPoppedOutOfWindow && window.origin === getBaseWindowOrigin()) {
        // to forward any new consultation request to the ccp window
        consultation_broadcast_channel.current.addEventListener(
          "message",
          OnMessage
        );
      }

      // Function to forward events caught by CCP window to forward them to TransferToConsultant module
      const OnEvent = (event: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (event.origin === getBaseWindowOrigin()) {
          // Only forward the event if the channel set for the event matches the current broadcast channel
          if (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            event.data.channel === consultation_broadcast_channel.current.name
          ) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            consultation_broadcast_channel.current.postMessage(event.data.data);
          }
        }
      };

      // Only add event listener to the CCP window to catch events from the main window
      if (getCcpWindowUrl().startsWith(window.origin)) {
        // add event listener to forward upcoming consultation requests
        window.addEventListener("message", OnEvent);
      }

      // clean up the event listeners on re-render
      return () => {
        if (isCcpPoppedOutOfWindow && window.origin === getBaseWindowOrigin()) {
          consultation_broadcast_channel.current.removeEventListener(
            "message",
            OnMessage
          );
        } else if (getCcpWindowUrl().startsWith(window.origin)) {
          window.removeEventListener("message", OnEvent);
        }
      };
    }, [isCcpPoppedOutOfWindow]);

    // remove the broadcast channel when the component is unmounted
    useEffect(() => {
      return () => {
        consultation_broadcast_channel.current.close();
      };
    }, []);

    const sendMessageToOpener = (message: WindowMessage) => {
      const baseWindowOrigin = getBaseWindowOrigin();
      // make sure this is a pop up opened from Omnia
      if (isWindowedCcp() && window.opener && baseWindowOrigin) {
        console.debug(
          "[ITSupportConnectAgentClient] Post a message to parent window:",
          message
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
        window.opener?.postMessage(message, baseWindowOrigin);
      }
    };

    // When CCP window is in popup and the selected instance gets updated, send a message to the parent window.
    useEffect(() => {
      const baseWindowOrigin = getBaseWindowOrigin();

      console.debug(
        "[ITSupportConnectAgentClient] Selected instance updated.",
        {
          baseWindowOrigin,
          isWindowedCcp: isWindowedCcp(),
        }
      );
      sendMessageToOpener({
        type: "SELECTED_INSTANCE_CHANGE",
        selectedInstance,
      });
    }, [selectedInstance]);

    useEffect(() => {
      sendMessageToOpener({
        type: "AGENT_CHAT_LANGUAGE_CHANGE",
        agentChatLanguage,
      });
      Translation.instance.setAgentChatLanguage(agentChatLanguage);
    }, [agentChatLanguage]);

    useEffect(() => {
      if (isWindowedCcp()) {
        const agentChatLanguage = new URLSearchParams(
          window.location.search
        ).get("agentChatLanguage") as TranslationSupportedLanguage | undefined;
        dispatch(agentChatLanguageSet(agentChatLanguage));
      }
    }, [ccpInitialized]);

    // Local Vars
    const isCurrentInstanceSelected =
      !!selectedInstanceOption &&
      selectedInstance?.ccpUrl === selectedInstanceOption.id;

    /**
     * isHidden must be surfaced here because wrapping the container div
     * inside the CcpLoading widget creates a new html element on each render
     */
    const isHidden =
      loadingOrError.loading || loadingOrError.error || ccpPopupError;

    /**
     * Adds the "(Current)" labelTag to the options list
     */
    const instanceOptionsWithCurrent = useMemo(() => {
      return options.map((x) =>
        x.id === selectedInstance?.ccpUrl ? { ...x, labelTag: "(Current)" } : x
      );
    }, [options, selectedInstance?.ccpUrl]);

    /**
     * Adds the "(Current)" labelTag to the selectedOption
     */
    const selectedInstanceOptionWithCurrent = useMemo(() => {
      return isCurrentInstanceSelected
        ? {
            ...(selectedInstanceOption as Select.Option),
            labelTag: "(Current)",
          }
        : selectedInstanceOption;
    }, [selectedInstanceOption, isCurrentInstanceSelected]);

    // Handlers
    const handleLaunchClick = (): void => {
      if (selectedInstanceOption) {
        const instance = instances.find(
          (i) => i.ccpUrl === selectedInstanceOption.id
        );

        // Reset selected instance when there's an error in CCP loading state
        // This allows the component to re-initialize when the user clicks "Try Again"
        if (loadingOrError.error) {
          setSelectedInstance(undefined);
        }
        setSelectedInstance(instance);
        setOptionsMenu(undefined);
      }
    };

    const handleDismiss = (): void => {
      setOptionsMenu(undefined);
      if (selectedInstance) {
        setSelectedInstanceOption(
          options.find((op) => selectedInstance.ccpUrl === op.id)
        );
      }
    };

    // Renders
    const renderWarning = (): React.ReactNode => {
      return (
        <div className="ccp-container-options-warning">
          <IconPolaris
            className="ccp-container-options-warning-icon"
            variant="link"
            name="status-info"
          />
          <div className="ccp-container-options-warning-text">
            {
              "All Connect contacts will be closed if you change instances while online"
            }
          </div>
        </div>
      );
    };

    const renderCcpContainerHeaderForCCp = (): JSX.Element => {
      return (
        <div className="ccp-container-header">
          <div className="ccp-container-header-left">
            <IconOmnia
              name="connect"
              variant="alternate"
              className="ccp-container-header-icon"
            />
            <div className="ccp-container-header-text">{"Amazon Connect"}</div>
          </div>
          <div className="ccp-container-header-right">
            {isTranslationEnabled() && (
              <IconPolaris
                name="settings"
                variant="normal"
                onClick={() => setOptionsMenu("Settings")}
                data-testid="ccp-container-header-settings-icon"
              />
            )}
            <IconOmnia
              name="globe"
              onClick={() =>
                optionsMenu === "Instance"
                  ? handleDismiss()
                  : setOptionsMenu("Instance")
              }
              variant="alternate"
              data-testid="ccp-container-header-instances-icon"
            />
            <IconOmnia
              name={isWindowedCcp() ? "pop-in" : "pop-out"}
              onClick={onPopClick}
              variant="alternate"
            />
            {!isWindowedCcp() && (
              <IconPolaris
                name="close"
                onClick={onCloseClick}
                variant="normal"
              />
            )}
          </div>
        </div>
      );
    };

    const getCcpContainerHeaderForPlaceholderCCp = (): JSX.Element => {
      return (
        <div>
          <div className="ccp-container-icon-cross">
            <IconPolaris
              name="close"
              className="ccp-container-icon-cross-close-icon"
              onClick={onCloseClick}
              variant="normal"
            />
          </div>
        </div>
      );
    };

    return (
      <div className="ccp-container" style={style}>
        {isCcpPoppedOutOfWindow
          ? getCcpContainerHeaderForPlaceholderCCp()
          : renderCcpContainerHeaderForCCp()}
        <div
          className="ccp-container-content"
          style={{
            display: isCcpPoppedOutOfWindow ? "none" : "contents",
          }}
        >
          {optionsMenu === "Instance" && (
            <div className="ccp-container-options">
              {!isCurrentInstanceSelected && renderWarning()}
              <div className="ccp-container-options-primary-text">
                {"Change Connect Instance"}
              </div>
              <div className="ccp-container-options-secondary-text">
                {"Please select an instance to launch"}
              </div>

              <Select
                placeholder={"Select Instance"}
                className={"ccp-container-options-instance-select"}
                options={instanceOptionsWithCurrent}
                selectedOption={selectedInstanceOptionWithCurrent}
                onChange={(e) =>
                  setSelectedInstanceOption(e.detail.selectedOption)
                }
                triggerVariant="option"
              />

              <div className="ccp-container-options-buttons">
                <Button variant="normal" onClick={handleDismiss}>
                  {"Cancel"}
                </Button>
                <Button
                  className="main-connect-instance-launch"
                  variant="primary"
                  onClick={handleLaunchClick}
                >
                  {"Change"}
                </Button>
              </div>
            </div>
          )}
          {optionsMenu === "Settings" && (
            <SettingsPanel onDismiss={() => setOptionsMenu(undefined)} />
          )}

          <CcpLoader onRetryClick={handleLaunchClick} />
          <div
            className="ccp-container-iframe"
            ref={ref}
            style={{
              display: isCcpPoppedOutOfWindow || isHidden ? "none" : "block",
            }}
          ></div>
          {/* TODO https://i.amazon.com/issues/CONC-8643 
          This is to consolidate the different CFM widgets. */}
          <TransferSurvey></TransferSurvey>
          <AssistSurveyPanel></AssistSurveyPanel>
          <TranslationSurvey></TranslationSurvey>
          <TransferToConsultant></TransferToConsultant>
          {enableChatLiveliness() && (
            <ExternalSessionControl></ExternalSessionControl>
          )}
        </div>

        {isCcpPoppedOutOfWindow && <PlaceholderCcp />}
      </div>
    );
  }
);

CcpContainer.displayName = "CcpContainer";
