import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
} from "react";

import { Action } from "./actions";
import { AppState } from "./app-state";
import { useAppState } from "./hooks";

/** Context that allows to obtain the app state. */
export const AppStateContext = createContext<AppState>(
  (undefined as unknown) as AppState
);

/** Context that allows to obtain the dispatcher. */
export const DispatchContext = createContext<Dispatch<Action>>(
  (undefined as unknown) as Dispatch<Action>
);

/** Provider that allows subcomponents to use the `useDispatch` and `useSelector` state hooks. */
export function AppStateProvider({
  children,
}: PropsWithChildren<unknown>): ReactElement {
  const [state, dispatch] = useAppState([
    "selectedInstance",
    "agentChatLanguage",
  ]);

  return (
    <AppStateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </AppStateContext.Provider>
  );
}
