import { TranslationSupportedLanguage } from "@amzn/it-support-connect-api-model";

import { translateMessage } from "../../connect-api";
import { CcpManager } from "../../connect/ccp-manager";
import { isTranslationEnabled } from "../config";

export class Translation {
  /**
   * For now, only agents that speaks English will have access to the feature.
   */
  public static readonly DEFAULT_AGENT_CHAT_LANGUAGE = "en";
  /**
   * Translation is only supported for the following source apps.
   */
  public static readonly TRANSLATION_ENABLED_SOURCE_APPS = ["IT_PORTAL"];
  /**
   * Translation is only supported for the following languages.
   * The key is the language code used by Connect API.
   * The value is the language code used by Amazon Translate.
   */
  private static readonly SUPPORTED_LANGUAGE_CODES: Record<
    string,
    TranslationSupportedLanguage
  > = {
    fra: "fr",
    jpn: "ja",
    zho: "zh",
    deu: "de",
    ita: "it",
    kor: "ko",
    por: "pt",
    spa: "es",
  };

  private static readonly singleton = new Translation();
  private agentChatLanguage:
    | TranslationSupportedLanguage
    | undefined = undefined;
  private initialized = false;

  public static get instance(): Translation {
    return Translation.singleton;
  }

  /**
   * This function sets up a message listener that captures outgoing agent message, translates the content, and sends out the translation in an application/json message.
   */
  public initTranslation(): void {
    // do nothing translation has been initialized
    if (this.initialized) {
      return;
    }

    this.initialized = true;

    CcpManager.instance.onChatConnected(({ connectedContact, chatSession }) => {
      if (!this.isTranslationEnabledForContact(connectedContact)) {
        return;
      }

      // If translation is enabled, let customer client know
      if (this.agentChatLanguage) {
        const translationToggleMessage: StructuredChatMessage = {
          type: "TranslationToggle",
          content: {
            isEnabled: true,
          },
        };

        void chatSession.sendMessage({
          contentType: "application/json",
          message: JSON.stringify(translationToggleMessage),
        });
      }

      // The event listener still needs to be set up since the agent can turn translation on during the contact.
      chatSession.onMessage((message: connect.ChatMessageEvent) => {
        void (async () => {
          try {
            if (!this.agentChatLanguage) {
              return;
            }

            // Non-null assertion due to the check in isTranslationEnabledForContact
            const translationTargetLanguageCode =
              Translation.SUPPORTED_LANGUAGE_CODES[
                connectedContact.getAttributes()["language"]!.value
              ];

            const messageData = message.data;
            // capture an outgoing agent message
            // Only the messages that are actually sent by the agent need to be tranlsated,
            // and all of those messages have the ContentType "text/plain"
            if (
              messageData.Id &&
              messageData.Content &&
              messageData.ParticipantRole === "AGENT" &&
              messageData.ContentType === "text/plain" &&
              messageData.Type === "MESSAGE"
            ) {
              // translate the content for the original message
              const translateResponse = await translateMessage({
                Text: messageData.Content,
                SourceLanguageCode: this.agentChatLanguage,
                TargetLanguageCode: translationTargetLanguageCode,
              });
              // construct the translation message
              const translationMessage: StructuredChatMessage = {
                type: "Translation",
                content: {
                  messageId: messageData.Id,
                  translatedText: translateResponse.TranslatedText,
                  sourceLanguageCode: this.agentChatLanguage,
                  targetLanguageCode: translationTargetLanguageCode,
                },
              };
              // send the translation in an application/json message
              await chatSession.sendMessage({
                contentType: "application/json",
                message: JSON.stringify(translationMessage),
              });
            }
          } catch (err) {
            console.error("Could not send translation", err);
          }
        })();
      });
    });
  }

  /**
   * This function sets the preferred chat language for the agent.
   * @param agentChatLanguage
   */
  public setAgentChatLanguage(
    agentChatLanguage: TranslationSupportedLanguage | undefined
  ): void {
    this.agentChatLanguage = agentChatLanguage;
  }

  /**
   * This function toggles on and off translation.
   * This is called when user changes the settings, since it will need to let the customer side know about the latest setting.
   * @param agentChatLanguage the new preferred chat language for the agent
   */
  public toggleTranslation(
    agentChatLanguage: TranslationSupportedLanguage | undefined
  ): void {
    // if the language has not changed, nothing needs to be done.
    if (this.agentChatLanguage === agentChatLanguage) {
      return;
    }

    // set the language
    this.setAgentChatLanguage(agentChatLanguage);

    // The following is to notify the customer's chat window that translation is enabled or disabled
    // This is because translation for customer messages are done on the customer side for the POC.
    connect.agent((agent) => {
      const translationToggleMessage: StructuredChatMessage = {
        type: "TranslationToggle",
        content: {
          isEnabled: !!this.agentChatLanguage,
        },
      };
      // Get all connected chats
      const connectedChats = agent
        .getContacts(connect.ContactType.CHAT)
        .filter(
          (contact) =>
            contact.getState().type === connect.ContactStateType.CONNECTED
        );

      // notify customer client
      connectedChats.forEach((contact) => {
        // skip if translatiion is not enabled for specific contacts (based on their source app and language)
        if (!this.isTranslationEnabledForContact(contact)) {
          return;
        }

        void (async () => {
          const agentConnection = contact.getAgentConnection();
          if (agentConnection instanceof connect.ChatConnection) {
            const chatSession = (await agentConnection.getMediaController()) as connect.ChatSession;
            await chatSession.sendMessage({
              contentType: "application/json",
              message: JSON.stringify(translationToggleMessage),
            });
          }
        })();
      });
    });
  }

  /**
   * This function tells if translation is enabled for the given contact.
   * @param contact the contact
   * @returns a boolean that indicates if translation is enabled for the contact
   */
  private isTranslationEnabledForContact(contact: connect.Contact): boolean {
    const contactAttributes = contact.getAttributes();
    const contactLanguage = contactAttributes["language"]?.value;
    const sourceApp = contactAttributes["sourceApp"]?.value;
    const translationTargetLanguageCode = contactLanguage
      ? Translation.SUPPORTED_LANGUAGE_CODES[contactLanguage]
      : undefined;
    // check:
    // 1. if customer language is not English and supported by translation
    // 2. the source app for the contact is supported
    return (
      !!translationTargetLanguageCode &&
      !!sourceApp &&
      Translation.TRANSLATION_ENABLED_SOURCE_APPS.includes(sourceApp)
    );
  }
}
