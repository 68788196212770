import "./style.scss";

import {
  Box,
  Button,
  SpaceBetween,
  Toggle,
} from "@amzn/awsui-components-react-v3";
import React, { useState } from "react";

import { agentChatLanguageSet } from "../../state/actions";
import { useDispatch, useSelector } from "../../state/hooks";
import { Translation } from "../Translation";

interface SettingsPanelProps {
  onDismiss: () => void;
}

export function SettingsPanel(props: SettingsPanelProps): JSX.Element {
  const dispatch = useDispatch();
  const agentChatLanguage = useSelector((state) => state.agentChatLanguage);
  const [selectedAgentChatLanguage, setSelectedAgentChatLanguage] = useState(
    agentChatLanguage
  );

  const onSave = () => {
    dispatch(agentChatLanguageSet(selectedAgentChatLanguage));
    Translation.instance.toggleTranslation(selectedAgentChatLanguage);
    props.onDismiss();
  };

  return (
    <div className="setting-container awsui-visual-refresh">
      <SpaceBetween direction="vertical" size="xs">
        <Box variant="h3">Settings</Box>
        <Toggle
          checked={!!selectedAgentChatLanguage}
          onChange={(event) =>
            setSelectedAgentChatLanguage(
              event.detail.checked
                ? Translation.DEFAULT_AGENT_CHAT_LANGUAGE
                : undefined
            )
          }
          description="Translation is only available if you speak English."
        >
          Chat message translation
        </Toggle>
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xxxs" alignItems="end">
            <Button variant="link" onClick={props.onDismiss}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onSave}>
              Save
            </Button>
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </div>
  );
}
