/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { TranslationSupportedLanguage } from "@amzn/it-support-connect-api-model";

import { AmazonConnectInstance } from "../profiles-reader";
import { ErrorMember, LoadingMember } from "./loading";

export function ccpPopupError(error: Error) {
  return { type: "CCP_POPUP_ERROR", error } as const;
}

export function setCcpInitialized(initialized: boolean) {
  return { type: "SET_CCP_INITIALIZED", initialized } as const;
}

export function setCcpLoadingState(member: ErrorMember | LoadingMember) {
  return { type: "SET_CCP_LOADING_STATE", member } as const;
}

export function setCcpInPopup(inPopup: boolean) {
  return { type: "SET_CCP_IN_POPUP", inPopup } as const;
}

export function setInstances(instances: AmazonConnectInstance[]) {
  return { type: "SET_INSTANCES", instances } as const;
}

export function setProfilesConfigLoadingState(
  member: ErrorMember | LoadingMember
) {
  return { type: "SET_PROFILES_CONFIG_LOADING_STATE", member } as const;
}

export function setSelectedInstance(selectedInstance?: AmazonConnectInstance) {
  return { type: "SET_SELECTED_INSTANCE", selectedInstance } as const;
}

export function addConsultedContact(contactId: string) {
  return { type: "ADD_CONSULTED_CONTACT", contactId } as const;
}

export function removeConsultedContact(contactId: string) {
  return { type: "REMOVE_CONSULTED_CONTACT", contactId } as const;
}

export function agentChatLanguageSet(
  agentChatLanguage: TranslationSupportedLanguage | undefined
) {
  return { type: "SET_AGENT_CHAT_LANGUAGE", agentChatLanguage } as const;
}

/** Enumerates all the actions that can be dispatched. */
export type Action =
  | ReturnType<typeof ccpPopupError>
  | ReturnType<typeof setCcpInitialized>
  | ReturnType<typeof setCcpLoadingState>
  | ReturnType<typeof setCcpInPopup>
  | ReturnType<typeof setInstances>
  | ReturnType<typeof setProfilesConfigLoadingState>
  | ReturnType<typeof setSelectedInstance>
  | ReturnType<typeof addConsultedContact>
  | ReturnType<typeof removeConsultedContact>
  | ReturnType<typeof agentChatLanguageSet>;
