import { Stage } from "../../configuration";
import { getStage, quitanievesEndpoint } from "../config";

export interface CfmWidgetConfig {
  productName: string;
  featureName: string;
  quitanievesEndpoint: string;
  type: string;
  timeoutMessageContent: string;
  dropdownQuestions?: {
    questionTag: string;
    questionLabel: string;
    dropDownOptions: string[];
    mainQuestion: string;
    followUpQuestions?: string;
    endSurveyAnswers?: number[];
    followUpTriggerAnswers?: number[];
    skipToQuestionTriggerAnswers?: number[];
    skipToQuestionIndex?: number;
  }[];
  language: string;
  username: string;
  connect_id: string;
  dedupe_id: string;
  followUpNote?: string;
  optionalFeedbackPlaceholder?: string;
  tyMessageHeader: string;
  tyMessageContent: string;
  customInlineStyle?: string;
  stage: Stage;
  notifyOnCompletion?: true;
  cesQuestions?: {
    questionTag: string;
    mainQuestion: string;
    followUpQuestions: {
      lowRating: string;
      highRating: string;
    };
  }[];
  cesRatingLabels?: {
    max: string;
    min: string;
  };
  maxCesRatingToDisplayFirstFollowUpQuestion?: number;
  minCesRatingToDisplaySecondFollowUpQuestion?: number;
}

export function getAssistFeedbackWidgetConfig(
  agentUsername: string,
  contactID: string
): CfmWidgetConfig {
  return {
    productName: "ASSIST",
    language: "en",
    username: agentUsername,
    connect_id: contactID,
    dedupe_id: contactID + "-assist-agent",
    type: "drop-down",
    featureName: "ASSISTAgentChatWidget",
    timeoutMessageContent:
      "The time to answer this feedback has expired. Please provide feedback in the follow up email.",
    quitanievesEndpoint: quitanievesEndpoint(),
    dropdownQuestions: [
      {
        questionTag: "consultation-type",
        questionLabel: "Question 1/4",
        mainQuestion: "What was the type of Consultation?",
        dropDownOptions: [
          "Requesting second opinion",
          "Exhausted troubleshooting",
          "Unclear KB/IT Article",
          "Tooling not working as expected",
          "Permissions gated/Non-IT/Other",
        ],
        endSurveyAnswers: [4],
      },
      {
        questionTag: "kb-article-covered",
        questionLabel: "Question 2/4",
        mainQuestion: "Is this issue covered by a Knowledge Base article?",
        dropDownOptions: ["Yes", "No"],
        followUpQuestions:
          "Provide the KB link and indicate which steps the engineer should have followed",
        followUpTriggerAnswers: [0],
      },
      {
        questionTag: "engineer-attempt-troubleshoot",
        questionLabel: "Question 3/4",
        mainQuestion:
          "Did the engineer attempt troubleshooting before reaching out?",
        dropDownOptions: ["Yes", "No", "Not Applicable"],
      },
      {
        questionTag: "issue-complexity",
        questionLabel: "Question 4/4",
        mainQuestion: "How complex was the issue you were asked to support",
        dropDownOptions: [
          "1 (Common Knowledge)",
          "2 (Intermediate Issue)",
          "3 (Advanced Issue)",
        ],
      },
    ],
    optionalFeedbackPlaceholder: "Comments",
    followUpNote:
      "Note: Do not include personal or customer data in this feedback",
    tyMessageHeader: "We appreciate your feedback!",
    tyMessageContent:
      "Your opinion is highly valued and will be used to improve your experience.",
    notifyOnCompletion: true,
    stage: getStage() === "gamma" ? "prod" : getStage(),
  };
}

export function getTransferFeedbackWidgetConfig(
  agentUsername: string,
  contactID: string
): CfmWidgetConfig {
  return {
    productName: "TRANSFERS",
    language: "en",
    username: agentUsername,
    connect_id: contactID,
    dedupe_id: contactID + "-tier2-agent",
    type: "drop-down",
    featureName: "TransferAgentChatWidget",
    timeoutMessageContent:
      "The time to answer this feedback has expired. Please provide feedback in the follow up email.",
    quitanievesEndpoint: quitanievesEndpoint(),
    dropdownQuestions: [
      {
        questionTag: "assist-consultation",
        questionLabel: "Question 1",
        mainQuestion:
          "Could this issue have been resolved using an Assist Consultation? (Select No if the engineer used Assist prior to escalating.)",
        dropDownOptions: ["Yes", "No"],
        skipToQuestionTriggerAnswers: [0],
        skipToQuestionIndex: 3,
      },
      // If a user selects "No" to "assist-consultation", proceed down this path
      {
        questionTag: "escalation-ticket-created",
        questionLabel: "Question 2",
        mainQuestion:
          "Was a ticket created with troubleshooting notes prior to escalation?",
        dropDownOptions: ["Yes", "No"],
      },
      {
        questionTag: "why-escalation-correct",
        questionLabel: "Question 3",
        mainQuestion: "Why was the escalation correct?",
        dropDownOptions: [
          "Permissions Gated Issue",
          "Complex Issue",
          "Incorrect KB/IT Article",
          "Previous Assist Consultation",
          "Assist Unavailable",
          "Emergent Issue",
          "Other",
        ],
        followUpTriggerAnswers: [6],
        followUpQuestions:
          "Please provide additional info on why the escalation was correct",
        endSurveyAnswers: [0, 1, 2, 3, 4, 5, 6], // "No" to "assist-consultation" path ends here
      },
      // If a user selects "Yes" to "assist-consultation", proceed down this path
      {
        questionTag: "escalation-ticket-created",
        questionLabel: "Question 2",
        mainQuestion:
          "Was a ticket created with troubleshooting notes prior to escalation?",
        dropDownOptions: ["Yes", "No"],
      },
      {
        questionTag: "why-escalated",
        questionLabel: "Question 3",
        mainQuestion: "Why was the contact escalated?",
        dropDownOptions: [
          "Attempted troubleshooting but did not use Assist",
          "Did not ask correct questions of customer",
          "Did not perform basic troubleshooting",
          "Did not follow all steps in a KB/IT article",
          "Followed an incorrect KB/IT article",
          "Resolution documented in non-KB resources",
          "Going on break/end of shift",
          "Other",
        ],
        followUpTriggerAnswers: [7],
        followUpQuestions:
          "Please provide additional info on why the contact was escalated",
      },
      {
        questionTag: "corrective-action",
        questionLabel: "Question 4",
        mainQuestion: "Corrective Action Recommendations",
        followUpQuestions:
          "Please provide relevant troubleshooting steps and KB links.",
        dropDownOptions: [],
      },
    ],
    optionalFeedbackPlaceholder: "Comments",
    followUpNote:
      "Note: Do not include personal or customer data in this feedback",
    tyMessageHeader: "We appreciate your feedback!",
    tyMessageContent:
      "Your opinion is highly valued and will be used to improve your experience.",
    notifyOnCompletion: true,
    stage: getStage() === "gamma" ? "prod" : getStage(),
  };
}

export function getTranslationFeedbackWidgetConfig(
  agentUsername: string,
  contactId: string
): CfmWidgetConfig {
  return {
    productName: "ITSConnectAgentClient",
    featureName: "Translation",
    cesQuestions: [
      {
        questionTag: "translation-service-satisfaction",
        mainQuestion:
          "Overall, How did you find our live translation experience?",
        followUpQuestions: {
          lowRating:
            "How could have the live translation experience been better?",
          highRating: "How was the live translation experience excellent?",
        },
      },
    ],
    cesRatingLabels: {
      max: "Excellent",
      min: "Not Helpful At All",
    },
    language: "en",
    maxCesRatingToDisplayFirstFollowUpQuestion: 4,
    minCesRatingToDisplaySecondFollowUpQuestion: 5,
    stage: getStage() === "gamma" ? "prod" : getStage(),
    tyMessageContent:
      "Your opinion is highly valued and will be used to improve your experience.",
    tyMessageHeader: "We appreciate your feedback!",
    username: agentUsername,
    quitanievesEndpoint: quitanievesEndpoint(),
    type: "ces-nps-survey",
    timeoutMessageContent:
      "The time to answer this feedback has expired. Please provide feedback in the follow up email.",
    connect_id: contactId,
    dedupe_id: contactId,
  };
}
