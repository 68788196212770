import { TranslationSupportedLanguage } from "@amzn/it-support-connect-api-model";

import { AmazonConnectInstance } from "../profiles-reader";
import { LoadingState } from "./loading";

/**
 * The version of persisted state
 * !!! WARNING !!!
 * This must be updated when making breaking changes to PersistedAppState
 *  */
export const VERSION_NUMBER = "1.0" as const;

/** The parts of the application state that are persisted. */
export interface PersistedAppState {
  readonly version: "1.0";
  /** The selected instance. */
  readonly selectedInstance?: AmazonConnectInstance;
  /**
   * The agent's preferred language for chat.
   */
  readonly agentChatLanguage?: TranslationSupportedLanguage;
}

/** The parts of the application that are not persisted. */
export interface VolatileAppState {
  /** Indicates whether the CCP has been initialized. */
  readonly ccpInitialized: boolean;

  /** Indicates whether the CCP is running on a popup. */
  readonly ccpInPopup: boolean;

  /** Indicates whether Amazon Connect's CCP is loading. */
  readonly ccpLoading: LoadingState;

  /** Indicates whether the CCP popup failed to open. */
  readonly ccpPopupError?: Error;

  /** The available instances. */
  readonly instances: AmazonConnectInstance[];

  /** Indicates whether profiles.json is loading. */
  readonly profilesConfigLoading: LoadingState;

  /** List of contacts for which consultation was started */
  readonly consultedContacts: string[];
}

/** The application state. */
export interface AppState extends PersistedAppState, VolatileAppState {}

/** The initial state. */
export const INITIAL_STATE: AppState = {
  version: VERSION_NUMBER,
  ccpInitialized: false,
  ccpInPopup: false,
  ccpLoading: { loading: false },
  instances: [],
  profilesConfigLoading: { loading: false },
  consultedContacts: [],
};
