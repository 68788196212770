export const ITS_MESSAGE_EXTERNAL_SESSION_STRINGS: Record<string, string> = {
  eng:
    "Please use this link to download the remote software and start a session. \n\n Please close or minimize sensitive information on the screen before starting the session. \n\n This tool allows you to share your screen and give the engineering support team access to help you.",
  spa:
    "Utilice este enlace para descargar el software remoto e iniciar una sesión. \n\n Cierre o minimice la información confidencial de la pantalla antes de iniciar la sesión. \n\n Esta herramienta le permite compartir su pantalla y dar acceso al equipo de soporte de ingeniería para que lo ayude.",
  deu:
    "Bitte benutzen Sie diesen Link, um die Remote-Software herunterzuladen und eine Sitzung zu starten. \n\n Bitte schließen oder minimieren Sie vertrauliche Informationen auf dem Bildschirm, bevor Sie die Sitzung starten. \n\n Mit diesem Tool können Sie Ihren Bildschirm teilen und dem technischen Support-Team Zugriff gewähren, um Ihnen zu helfen.",
  por:
    "Use este link para baixar o software remoto e iniciar uma sessão. \n\n Feche ou minimize as informações confidenciais na tela antes de iniciar a sessão. \n\n Essa ferramenta permite que você compartilhe sua tela e dê acesso à equipe de suporte de engenharia para ajudá-lo.",
  fra:
    "Cliquez sur ce lien pour télécharger le logiciel à distance et démarrer une session. \n\n Veuillez fermer ou minimiser les informations sensibles à l'écran avant de démarrer la session. \n\n Cet outil vous permet de partager votre écran et de donner à l'équipe d'assistance technique l'accès pour vous aider.",
  ita:
    "Utilizza questo link per scaricare il software remoto e avviare una sessione. \n\n Chiudi o minimizza le informazioni sensibili sullo schermo prima di iniziare la sessione. \n\n Questo strumento ti consente di condividere lo schermo e fornire al team di supporto tecnico l'accesso per aiutarti.",
  jpn:
    "このリンクを使用してリモートソフトウェアをダウンロードし、セッションを開始してください。\n\n セッションを開始する前に、画面上の機密情報を閉じるか最小化してください \n\n このツールを使用すると、画面を共有し、エンジニアリングサポートチームにアクセスして支援してもらうことができます。",
  kor:
    "이 링크를 사용하여 원격 소프트웨어를 다운로드하고 세션을 시작하십시오. \n\n 세션을 시작하기 전에 화면의 민감한 정보를 닫거나 최소화하십시오. \n\n 이 도구를 사용하면 화면을 공유하고 엔지니어링 지원 팀에 도움을 줄 수 있습니다.",
  zho:
    "请使用此链接下载远程软件并启动会话。\n\n 在开始会话之前，请关闭或最小化屏幕上的敏感信息 \n\n 此工具允许您共享屏幕，并允许工程支持团队访问以帮助您。",
};
