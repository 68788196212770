import {
  ApiErrorResponse,
  CreateExternalSessionBody,
  CreateExternalSessionResponse,
  ExternalSession,
  GetCsrfTokenResponse,
  GetExternalSessionsResponse,
  TranslateMessageRequest,
  TranslateMessageResponse,
} from "@amzn/it-support-connect-api-model";

import { connectApiHostname } from "./components/config";
import { configuredSentryFetch } from "./configured-sentry-fetch";
import { logger } from "./logger";

/* Fetch CSRF token for the user
 * @param csrfData Data used to create a unique csrf token
 */
async function fetchCsrfToken(csrfData: string): Promise<GetCsrfTokenResponse> {
  const response = await configuredSentryFetch(
    `${connectApiHostname()}/midway/csrf-token`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "x-csrf-data": csrfData,
      },
    }
  );
  const json = (await response.json()) as unknown;
  if (response.ok) {
    return json as GetCsrfTokenResponse;
  } else {
    logger.info(`Failed to fetch CSRF token due to ${JSON.stringify(json)}`);
    throw new Error(JSON.stringify(json));
  }
}

/* Fetch existing external sessions for a particular Amazon Connect contact
  @param contactId Amazon contact Id of the customer
*/
export async function fetchExternalSessions(
  contactId: string,
  originalContactId: string
): Promise<ExternalSession[]> {
  // get external sessions
  const response = await configuredSentryFetch(
    `${connectApiHostname()}/midway/contacts/${contactId}/external-sessions`,
    {
      method: "GET",
      credentials: "include",
      headers: {},
    }
  );
  const json = (await response.json()) as unknown;
  if (response.ok) {
    return (json as GetExternalSessionsResponse).externalSessions;
  } else {
    logger.info(
      `Failed to fetch external sessions for contactID: ${contactId}`
    );
    throw new Error(JSON.stringify(json));
  }
}

/* This function starts an external session.
  @param contactId Amazon contact Id of the customer
*/
export async function startSession(
  contactId: string,
  createExternalSessionBody: CreateExternalSessionBody
): Promise<ExternalSession> {
  const body = JSON.stringify(createExternalSessionBody);
  const path = `/midway/contacts/${contactId}/external-sessions`;
  const httpMethod = "POST";
  const csrfData = `${path}-${httpMethod}-${body}`;
  const csrfToken = await fetchCsrfToken(csrfData);

  // create external session
  const response: Response = await configuredSentryFetch(
    `${connectApiHostname()}${path}`,
    {
      method: httpMethod,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "x-csrf-token": csrfToken.token,
      },
      body: body,
    }
  );
  const json = (await response.json()) as unknown;
  if (response.ok) {
    return (json as CreateExternalSessionResponse).externalSession;
  } else {
    if (response.status === 400) {
      if ((json as ApiErrorResponse).code === "AGENT_NOT_LOGGED_IN_BOMGAR") {
        logger.info(`The calling agent is not logged into bomgar.`);
        throw new Error(`You are currently not logged into RemoteSupport.`);
      } else if ((json as ApiErrorResponse).code === "CONTACT_NOT_VERIFIED") {
        logger.info(
          `No verified verification id found for contact: ${contactId}`
        );
        throw new Error("Please ensure that the customer is RIV verified");
      } else {
        // Should not get to here. This API only returns 400 under above conditions.
        logger.info(`Failed to start a session for contact: ${contactId}`);
        throw new Error("Failed to start a session");
      }
    } else {
      logger.info(`Failed to start a session for contact: ${contactId}`);
      throw new Error("Failed to start a session");
    }
  }
}

/**
 * This function makes a call to the backend to translate the provided message into the specified language.
 * @param request the request for translation
 * @returns the response for the translation
 */
export async function translateMessage(
  request: TranslateMessageRequest
): Promise<TranslateMessageResponse> {
  const response = await configuredSentryFetch(
    `${connectApiHostname()}/midway/translate-message`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    }
  );

  return (await response.json()) as TranslateMessageResponse;
}
