import { configure } from "@amzn/sentry-fetch";

import { getStage } from "./components/config";

/**
 * This is the sentryFetch function confiured to pop up a Midway login screen when there is no valid Midway session.
 */
export const configuredSentryFetch = configure({
  followMidwayStepUp: true,
  followMidwayStepUpOptions: { mode: "modal" },
  debug: getStage() === "dev",
});
