import "./style.scss";

import {
  ButtonDropdown,
  ButtonDropdownProps,
  Link,
  SpaceBetween,
  Spinner,
  StatusIndicator,
  StatusIndicatorProps,
} from "@amzn/awsui-components-react-v3";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import CopyToClipboard from "@amzn/awsui-components-react-v3/polaris/copy-to-clipboard";
import { ExternalSession } from "@amzn/it-support-connect-api-model";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";

import { fetchExternalSessions, startSession } from "../../connect-api";
import { useSelector } from "../../state/hooks";
import { bomgarLoginLink } from "../config";
import { ITS_MESSAGE_EXTERNAL_SESSION_STRINGS } from "./constants";

export interface ContactInfo {
  readonly contactId: string;
  readonly originalContactId: string;
  readonly contactType: connect.ContactType;
  readonly language: string | undefined;
}

export type BomgarPortal = "DEFAULT" | "ACCESSIBILITY";

interface ExternalSessionPanelProps {
  readonly contactInfo: ContactInfo;
  readonly sendMessage: (
    contactId: string,
    message: string,
    contentType: connect.ChatMessageContentType
  ) => void;
}

interface Message {
  content: string;
  type: "SUCCESS" | "WARNING" | "ERROR";
}

enum LoadStatus {
  Loaded,
  Loading,
  Error,
}

export const ExternalSessionPanel: React.FC<ExternalSessionPanelProps> = (
  props
) => {
  // Local State
  const [expanded, setExpanded] = useState<boolean>(false);
  const [message, setMessage] = useState<Message | undefined>(undefined);
  const [activeSession, setActiveSession] = useState<ExternalSession>();
  const [channelOrSessionInCreation, setChannelOrSessionInCreation] = useState<
    boolean
  >(false);
  const [loadStatus, setLoadStatus] = useState<LoadStatus>(LoadStatus.Loaded);
  const [bomgarPortal, setBomgarPortal] = useState<BomgarPortal>("DEFAULT");

  // Refs
  const sessionUrlExpiryTimer: MutableRefObject<number | undefined> = useRef(
    undefined
  );
  const displayBomgarLoginLink: MutableRefObject<boolean> = useRef(false);

  // Constants
  const bomgarPortalItems: ButtonDropdownProps.Item[] = [
    {
      text: `Default`,
      id: "DEFAULT",
      disabled: channelOrSessionInCreation,
    },
    {
      text: `Accessibility`,
      id: "ACCESSIBILITY",
      disabled: channelOrSessionInCreation,
    },
  ];

  // Timeout for checking session key expiry
  const setTimeoutUrlExpiryActions = () => {
    // Compute time to expiry of the key
    const expiresInMs =
      new Date(activeSession?.externalSessionKeyExpiry as string).getTime() -
      Date.now();

    // Set timeout to trigger the expiry check when session has SESSION_URL_GENERATED status
    if (activeSession?.status === "SESSION_URL_GENERATED") {
      sessionUrlExpiryTimer.current = window.setTimeout(() => {
        // setTimeout function cannot be marked as async, so do the following workaround
        const checkSession = async () => {
          const session = await fetchExternalSessions(
            props.contactInfo.contactId,
            props.contactInfo.originalContactId
          );
          /**
           * The callback called after session link expiry. If session.length = 0, then
           * the link has expired. The session cannot have been ended because the timeout
           * timer is removed when activeSession state is set to undefined by `endExternalSession`.
           * If session has started, then session.length > 0 and this warning will not show.
           */
          if (session.length === 0) {
            setMessage({
              content: `The session key was not used and has expired. To continue with external sessions, generate a new key.`,
              type: "WARNING",
            });
          }
        };

        // Call function and catch/log errors
        checkSession().catch((err) => console.error(err));
      }, Math.max(expiresInMs + 1000, 0)); // Give an extra second so the key is definitely expired. Do no accept negative timeout values
    } else {
      sessionUrlExpiryTimer.current = undefined;
    }
  };

  // To return the localized string
  const getLocalizedString = (): string => {
    return props.contactInfo.language &&
      ITS_MESSAGE_EXTERNAL_SESSION_STRINGS[props.contactInfo.language]
      ? ITS_MESSAGE_EXTERNAL_SESSION_STRINGS[props.contactInfo.language]
      : ITS_MESSAGE_EXTERNAL_SESSION_STRINGS["eng"];
  };

  useEffect(() => {
    // If setting a new active session, create new timeouts
    if (activeSession) {
      setTimeoutUrlExpiryActions();
    }

    // Cleanup function for this effect: clear the existing timeouts
    return () => {
      if (sessionUrlExpiryTimer.current) {
        clearTimeout(sessionUrlExpiryTimer.current);
        sessionUrlExpiryTimer.current = undefined;
      }
    };
  }, [activeSession]);

  useEffect(() => {
    if (
      message?.type === "ERROR" &&
      message.content.includes("not logged into RemoteSupport")
    ) {
      displayBomgarLoginLink.current = true;
    } else {
      displayBomgarLoginLink.current = false;
    }
  }, [message]);

  // expand or collapse the controller
  const toggleControllerExpansion = (): void => {
    setExpanded(!expanded);
  };

  // fetch existing external sessions on page load
  const getExternalSessions = async (): Promise<void> => {
    setLoadStatus(LoadStatus.Loading);
    setExpanded(false);
    try {
      const externalSessions = await fetchExternalSessions(
        props.contactInfo.contactId,
        props.contactInfo.originalContactId
      );
      if (externalSessions.length > 0) {
        setActiveSession(externalSessions[0]);
        if (externalSessions[0].status === "SESSION_URL_GENERATED") {
          setMessage({
            content: getSessionCreateSuccessMessage(externalSessions[0]),
            type: "SUCCESS",
          });
        } else if (externalSessions[0].status === "STARTED") {
          setMessage({
            content: "Remote session in progress",
            type: "SUCCESS",
          });
        }
      } else {
        setActiveSession(undefined);
      }

      setLoadStatus(LoadStatus.Loaded);
    } catch (error) {
      setLoadStatus(LoadStatus.Error);
    }
  };

  // generate the session key generation message based on the type of contact
  const getSessionCreateSuccessMessage = (session: ExternalSession): string => {
    const localExpiryTime = new Date(
      session.externalSessionKeyExpiry!
    ).toLocaleTimeString();

    if (props.contactInfo.contactType === connect.ContactType.CHAT) {
      return `The session url has been sent to the customer. It is valid until ${localExpiryTime}. \n\n The 12-digit session key may also be used to start a session.`;
    } else if (props.contactInfo.contactType === connect.ContactType.VOICE) {
      return `Please copy and send the session key to the customer. It is valid until ${localExpiryTime}.`;
    }
    return `Successfully generated session key: ${session.externalSessionKeyUrl!}.`;
  };

  const startExternalSession = async (): Promise<void> => {
    setChannelOrSessionInCreation(true);
    setMessage(undefined);

    try {
      const session: ExternalSession = await startSession(
        props.contactInfo.contactId,
        { portal: bomgarPortal }
      );

      // Do not send session message when the current session is still active.
      if (
        activeSession?.startedTimestamp === session.startedTimestamp &&
        session.status === "STARTED"
      ) {
        setMessage({
          content: `An external session already started. Please end the current session before creating a new session.`,
          type: "WARNING",
        });
      } else {
        props.sendMessage(
          props.contactInfo.contactId,
          `${getLocalizedString()} \n\n ${session.externalSessionKeyUrl!}`,
          "text/plain"
        );
        setMessage({
          content: getSessionCreateSuccessMessage(session),
          type: "SUCCESS",
        });
      }
      setActiveSession({ ...session });
    } catch (err) {
      const error = err as Error;
      setMessage({
        content: error.message,
        type: "ERROR",
      });
      if (
        error.message.includes(
          "You are currently not logged into RemoteSupport."
        )
      ) {
        displayBomgarLoginLink.current = true;
      }
    }
    setChannelOrSessionInCreation(false);
  };

  // fetch external sessions when the component loads
  // note: when popped out, ccp creates a new window and sets contact id, which runs the following as well.
  useEffect(() => {
    void getExternalSessions();
  }, [props.contactInfo.contactId]);

  // fetch external session when ccp is popped in only
  const isCcpPoppedOutOfWindow = useSelector((state) => state.ccpInPopup);
  useEffect(() => {
    if (!isCcpPoppedOutOfWindow) {
      void getExternalSessions();
    }
  }, [isCcpPoppedOutOfWindow]);

  const getMainActionItem = (): ButtonDropdownProps.Item => {
    return bomgarPortalItems.find(
      (item) => item.id === bomgarPortal
    ) as ButtonDropdownProps.Item;
  };

  const getButtonDropdownItems = (): ButtonDropdownProps.Item[] => {
    return bomgarPortalItems.filter((item) => item.id !== bomgarPortal);
  };

  const setMainAction = (
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>
  ) => {
    setBomgarPortal(event.detail.id as BomgarPortal);
  };

  return (
    <div
      className={
        expanded
          ? "external-session-expanded-container"
          : "external-session-collapsed-container"
      }
    >
      {loadStatus === LoadStatus.Loading && (
        <div className="external-session-title-container">
          <SpaceBetween direction="horizontal" size="xxs">
            <div className="external-session-spinner">
              <Spinner variant="inverted"></Spinner>
            </div>
            <div className="external-session-container-title">
              Loading external sessions...
            </div>
          </SpaceBetween>
        </div>
      )}
      {loadStatus === LoadStatus.Error && (
        <div className="external-session-title-container">
          <SpaceBetween direction="horizontal" size="xxs">
            <div className="external-session-error-icon">
              <StatusIndicator type="error"></StatusIndicator>
            </div>
            <div className="external-session-container-title">
              Failed to load external sessions.
            </div>
            <Button
              iconName="refresh"
              variant="icon"
              className="awsui-visual-refresh awsui-polaris-dark-mode retry"
              onClick={() => void getExternalSessions()}
            />
          </SpaceBetween>
        </div>
      )}
      {loadStatus === LoadStatus.Loaded && (
        <div
          className="external-session-title-container"
          onClick={toggleControllerExpansion}
        >
          <SpaceBetween direction="horizontal" size="xxs">
            <Button
              className="awsui-visual-refresh awsui-polaris-dark-mode"
              iconName={expanded ? "caret-down-filled" : "caret-right-filled"}
              variant="icon"
              data-testid="external-session-panel-dropdown"
            />
            <div className="external-session-container-title">
              Generate session key
            </div>
          </SpaceBetween>
        </div>
      )}
      {expanded && !activeSession && (
        <div className="external-session-message-container">
          <div className="external-session-info-message">
            Select the public portal and click to generate session key.
          </div>
        </div>
      )}
      {expanded && (
        <div className="external-session-button-container">
          <SpaceBetween direction="horizontal" size="xxs">
            <ButtonDropdown
              variant="normal"
              className={`awsui-visual-refresh awsui-polaris-dark-mode bomgar-btn`}
              mainAction={{
                text: getMainActionItem().text,
                onClick: () => void startExternalSession(),
                disabled: channelOrSessionInCreation,
                loading: channelOrSessionInCreation,
              }}
              items={getButtonDropdownItems()}
              onItemClick={(event) => setMainAction(event)}
            />
            {activeSession && !channelOrSessionInCreation && (
              <CopyToClipboard
                copyButtonText="Session key"
                copyButtonAriaLabel="Copy session key"
                copyErrorText="Session key failed to copy"
                copySuccessText={`Copied session key: ${activeSession.externalSessionShortKey!}`}
                className="awsui-visual-refresh awsui-polaris-dark-mode copy-session-url-btn"
                textToCopy={activeSession.externalSessionShortKey!}
              />
            )}
          </SpaceBetween>
        </div>
      )}
      {message && expanded && (
        <div className="external-session-message-container">
          <StatusIndicator
            type={message.type.toLowerCase() as StatusIndicatorProps.Type}
          ></StatusIndicator>
          <div
            className={`external-session-${message.type.toLowerCase()}-message`}
          >
            {message.content}
            {displayBomgarLoginLink.current && (
              <span>
                {" "}
                Please click{" "}
                <Link external href={bomgarLoginLink()} variant="secondary">
                  here
                </Link>{" "}
                to login in.
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
