import { TranslationSupportedLanguage } from "@amzn/it-support-connect-api-model";

import { AmazonConnectInstance } from "../profiles-reader";
import { Action } from "./actions";
import { AppState } from "./app-state";
import { deriveLoadingState, LoadingState } from "./loading";

function ccpInitialized(state: boolean, action: Action): boolean {
  switch (action.type) {
    case "SET_CCP_INITIALIZED":
      return action.initialized;

    default:
      return state;
  }
}

function ccpInPopup(state: boolean, action: Action): boolean {
  switch (action.type) {
    case "SET_CCP_IN_POPUP":
      return action.inPopup;

    default:
      return state;
  }
}

function ccpLoading(state: LoadingState, action: Action): LoadingState {
  switch (action.type) {
    case "SET_CCP_LOADING_STATE":
      return deriveLoadingState(action.member);
    default:
      return state;
  }
}

function ccpPopupError(
  state: Error | undefined,
  action: Action
): Error | undefined {
  switch (action.type) {
    case "CCP_POPUP_ERROR":
      return action.error;

    case "SET_CCP_IN_POPUP":
      return undefined;

    default:
      return state;
  }
}

function instances(
  state: AmazonConnectInstance[],
  action: Action
): AmazonConnectInstance[] {
  switch (action.type) {
    case "SET_INSTANCES":
      return action.instances;
    default:
      return state;
  }
}

function profilesConfigLoading(
  state: LoadingState,
  action: Action
): LoadingState {
  switch (action.type) {
    case "SET_PROFILES_CONFIG_LOADING_STATE":
      return deriveLoadingState(action.member);
    case "SET_INSTANCES":
      return { loading: false };
    default:
      return state;
  }
}

function selectedInstance(
  state: AmazonConnectInstance | undefined,
  action: Action
): AmazonConnectInstance | undefined {
  switch (action.type) {
    case "SET_SELECTED_INSTANCE":
      return action.selectedInstance;

    default:
      return state;
  }
}

function updateConsultedContacts(state: string[], action: Action) {
  switch (action.type) {
    case "ADD_CONSULTED_CONTACT":
      return [...state, action.contactId];
    case "REMOVE_CONSULTED_CONTACT":
      return state.filter((contactId) => contactId != action.contactId);
    default:
      return state;
  }
}

function setAgentChatLanguage(
  state: TranslationSupportedLanguage | undefined,
  action: Action
): TranslationSupportedLanguage | undefined {
  switch (action.type) {
    case "SET_AGENT_CHAT_LANGUAGE":
      return action.agentChatLanguage;

    default:
      return state;
  }
}

export function rootReducer(state: AppState, action: Action): AppState {
  return {
    version: state.version,
    ccpInitialized: ccpInitialized(state.ccpInitialized, action),
    ccpInPopup: ccpInPopup(state.ccpInPopup, action),
    ccpLoading: ccpLoading(state.ccpLoading, action),
    ccpPopupError: ccpPopupError(state.ccpPopupError, action),
    instances: instances(state.instances, action),
    profilesConfigLoading: profilesConfigLoading(
      state.profilesConfigLoading,
      action
    ),
    selectedInstance: selectedInstance(state.selectedInstance, action),
    agentChatLanguage: setAgentChatLanguage(state.agentChatLanguage, action),
    consultedContacts: updateConsultedContacts(state.consultedContacts, action),
  };
}
