import { useCallback, useContext, useEffect, useState } from "react";

import { CONNECT_API } from "../../connect/api";
import { CcpManager, WindowMessage } from "../../connect/ccp-manager";
import { AmazonConnectInstance } from "../../profiles-reader";
import {
  agentChatLanguageSet,
  ccpPopupError,
  setCcpInitialized,
  setCcpInPopup,
  setCcpLoadingState,
  setSelectedInstance,
} from "../../state/actions";
import { useDispatch, useSelector } from "../../state/hooks";
import { OnCcpInitContext } from "../contexts";
import { useMounted } from "./common";

/**
 * Hook that returns a function to terminate the currently initialized CCP.
 * If there's no currently intiailized CCP, the function is a no-op.
 */
export function useTerminateCcp(): () => void {
  const dispatch = useDispatch();

  return useCallback(() => {
    if (CcpManager.instance.initialized) {
      CcpManager.instance.terminate();
      dispatch(setCcpInitialized(false));
    }
  }, [dispatch]);
}

/**
 * Hook that returns a function to initialize the CCP on a specified `HTMLElement` with a specified `AmazonConnectInstance`.
 * THe initialized CCP (if any) is automatically terminated when the component where this hook is used is unmounted.
 * If there's already an initialized CCP, the function is a no-op.
 */
function useInitCcp(): (
  container: HTMLElement,
  instance: AmazonConnectInstance
) => Promise<void> {
  const mounted = useMounted();
  const dispatch = useDispatch();
  const terminate = useTerminateCcp();

  // agent.js consumer callback to invoke when the CCP has initialized
  const onCcpInit = useContext(OnCcpInitContext);
  const selectedInstance = useSelector((state) => state.selectedInstance);

  // unload the CCP when the component unmounts
  useEffect(() => {
    return terminate;
  }, [terminate]);

  return useCallback(
    async (container, instance) => {
      if (!CcpManager.instance.initialized) {
        dispatch(setCcpLoadingState({ loading: true }));

        try {
          await CcpManager.instance.init(
            {
              container,
              instance,
              onWindowClose: () => {
                if (mounted.current) {
                  dispatch(setCcpInPopup(false));
                }
              },
              onMessageReceive: (message: WindowMessage) => {
                // If selectedInstance from message is different from the current one, update it in the Redux state.
                console.debug(
                  "[ITSupportConnectAgentClient] Received message: ",
                  message
                );
                console.debug(
                  "[ITSupportConnectAgentClient] Selected instance: ",
                  selectedInstance
                );
                if (
                  selectedInstance &&
                  message.type === "SELECTED_INSTANCE_CHANGE" &&
                  message.selectedInstance &&
                  message.selectedInstance.ccpUrl !== selectedInstance.ccpUrl
                ) {
                  dispatch(setSelectedInstance(message.selectedInstance));
                }
                if (message.type === "AGENT_CHAT_LANGUAGE_CHANGE") {
                  dispatch(agentChatLanguageSet(message.agentChatLanguage));
                }
              },
            },
            {
              onError: (error) => {
                dispatch(setCcpLoadingState({ error }));
              },
            }
          );

          // if the component is not mounted anymore, terminate CCP
          if (!mounted.current) {
            CcpManager.instance.terminate();
            return;
          }

          // notify agent.js consumer that CCP has been initialized
          if (onCcpInit) {
            try {
              onCcpInit(CONNECT_API);
            } catch (err) {
              // we catch since the callback is outside of agent.js control
              console.error(
                "An error ocurred while executing onCcpInit callback",
                err
              );
            }
          }

          dispatch(setCcpLoadingState({ loading: false }));
          dispatch(setCcpInitialized(true));
        } catch (err) {
          if (mounted.current) {
            dispatch(setCcpLoadingState({ error: err as Error }));
          }
        }
      }
    },
    [dispatch, mounted, onCcpInit, selectedInstance]
  );
}

/**
 * Hook that returns a function to initialize the CCP on a specified `HTMLElement` with the currently selected instance.
 * The initialized CCP (if any) is automatically terminated when the selected instance changes.
 * The initialized CCP (if any) is automatically terminated when the component where this hook is used is unmounted.
 * If there's already an initialized CCP, the funciton is a no-op.
 */
export function useInitSelectedInstanceCcp(): (
  container: HTMLElement
) => Promise<void> {
  const selectedInstance = useSelector((s) => s.selectedInstance);
  const init = useInitCcp();
  const terminate = useTerminateCcp();
  const [container, setContainer] = useState<HTMLElement | undefined>();

  const initContainer = useCallback(async () => {
    if (selectedInstance && container) {
      await init(container, selectedInstance);
    }
  }, [init, container, selectedInstance]);

  // unload the CCP when the selected instance changes
  useEffect(() => {
    terminate();
    void initContainer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstance, container]);

  return useCallback(
    async (container: HTMLElement) => {
      setContainer(container);
      await initContainer();
    },
    [initContainer, setContainer]
  );
}

/**
 * Hook that returns a function to open a popup to the currently initialized CCP.
 * If there's no currently initialized CCP, the function is a no-op.
 */
export function useOpenCcpWindow(): () => void {
  const dispatch = useDispatch();
  const consultedContacts = useSelector((state) => state.consultedContacts);
  const agentChatLanguage = useSelector((state) => state.agentChatLanguage);

  return useCallback(() => {
    if (CcpManager.instance.initialized) {
      try {
        CcpManager.instance.openWindow(
          encodeURIComponent(JSON.stringify(consultedContacts)),
          agentChatLanguage && encodeURIComponent(agentChatLanguage)
        );

        dispatch(setCcpInPopup(true));
      } catch (error) {
        dispatch(ccpPopupError(error as Error));
      }
    }
  }, [dispatch, consultedContacts, agentChatLanguage]);
}

export function useCloseCcpWindow(): () => void {
  const dispatch = useDispatch();

  return useCallback(() => {
    if (CcpManager.instance.initialized) {
      try {
        if (!CcpManager.instance.poppedCcpWindow) {
          CcpManager.instance.setPoppedCcpWindow({
            window: window.self,
            intervalHandle: 0,
          });
          CcpManager.instance.closeWindow();
        }
        dispatch(setCcpInPopup(false));
      } catch (error) {
        dispatch(ccpPopupError(error as Error));
      }
    }
  }, [dispatch]);
}
