import {
  LinkClickTrackingPlugin,
  trackLinkClick,
} from "@snowplow/browser-plugin-link-click-tracking";
import {
  newTracker,
  setUserId as snowplowSetUserId,
} from "@snowplow/browser-tracker";

import { Configuration } from "./configuration";
import { getQuitanievesEndpoint } from "./constants";

export const agentClientSnowplowTracker = "agentClient";

/**
 * Event types that are tracked using Snowplow.
 */
export enum TrackedEvent {
  MessageReceived = "message-received",
  ConnectionEstablished = "connection-established",
  ConnectionLost = "connection-lost",
  ConnectionBroken = "connection-broken",
  CCPError = "ccp-error",
  TranslationEnabled = "translation-enabled",
  TranslationDisabled = "translation-disabled",
}

/**
 * This function create the Snowplow tracker for chat for the specifed source APP.
 * @param sourceApp the source APP for which this chat widget is being used.
 * @param tracker the tracker to be created.
 */
export function createTracker(
  configuration: Configuration,
  tracker: string = agentClientSnowplowTracker
): void {
  newTracker(tracker, getQuitanievesEndpoint(configuration.stage), {
    appId: "AgentClient",
    contexts: {
      webPage: false,
    },
    discoverRootDomain: true,
    platform: "web",
    postPath: "/prod",
    plugins: [LinkClickTrackingPlugin()],
  });
}

/**
 * This function sets the user id for the tracker.
 * @param userId the alias of the user.
 * @param tracker the tracker for which the userId should be set.
 */
export function setUserId(
  userId: string,
  tracker: string = agentClientSnowplowTracker
): void {
  snowplowSetUserId(userId, [tracker]);
}

/**
 * This function emits the specified event using the Snowplow tracker.
 * @param event the event to be tracked.
 * @param tracker the tracker with which event should be emitted.
 */
export function trackEvent(
  event: Omit<
    Parameters<typeof trackLinkClick>[0],
    "elementId" | "targetUrl"
  > & { elementId: TrackedEvent; targetUrl?: string },
  tracker: string = agentClientSnowplowTracker
): void {
  trackLinkClick(
    {
      ...event,
      targetUrl: event.targetUrl ?? "#",
      elementClasses: event.elementClasses ?? [],
      elementTarget: event.elementTarget ?? "",
    },
    [tracker]
  );
}
